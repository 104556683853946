@import "../../resources/colours.css";

.projects-section {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Adjusted to min-height to allow content to expand */
  padding: 20px;
  background-color: var(--darkShades);
}

.projTitle {
  color: var(--lightShades);
  padding-bottom: 40px;
}

.projects-content {
  max-width: 1200px;
  width: 100%; /* Ensures the content takes up full width on smaller screens */
  margin: 0 auto;
  text-align: center;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.project-card {
  background: var(--lightShades);
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.2s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.project-card:hover {
  transform: scale(1.05);
}

.carousel .slide img {
  max-height: 300px;
  max-width: 100%;
  object-fit: contain;
  border-radius: 10px;
}

.project-description {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 16px;
  color: var(--darkShades);
}

.button {
  align-self: flex-end;
  width: 100%;
  padding: 10px 20px;
  background-color: var(--mainColor);
  color: var(--darkShades);
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
  font-weight: bold;
}

.button:hover {
  background-color: var(--lightAccent);
}

.soon {
  padding: 10px 20px;
  background-color: var(--darkAccent);
  color: var(--darkShades);
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.projectName {
  color: var(--darkShades);
  padding-bottom: 15px;
}

/* Responsive design for projects grid */
@media (max-width: 768px) {
  .projects-grid {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}

@media (max-width: 480px) {
  .projects-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}
