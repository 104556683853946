@import "../../resources/colours.css";

.contact-section {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(var(--darkAccent), var(--lightAccent));
    padding: 20px;
  }
  
  .contact-container {
    color: var(--darkShades);
    width: 80%;
    max-width: 600px;
    background-color: var(--lightShades);
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .contact-container:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .contact-container h2 {
    margin-bottom: 20px;
    font-size: 2rem;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 20px;
    text-align: left;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-size: 1rem;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  button {
    padding: 10px 20px;
    background-color: var(--mainColor);
    color: var(--darkShades);
    font-weight: bold;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: var(--darkAccent);
  }
  