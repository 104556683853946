@import "../../resources/colours.css";

.skills-page {
  height: 100vh;
  background-image: linear-gradient(var(--darkShades), var(--darkAccent));
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.skills-content {
  text-align: center;
}

.skills-title {
  font-size: 2rem;
  color: var(--lightShades);
  margin-bottom: 20px;
}

.skills-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
}

.skill {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--lightShades);
  border-radius: 10px;
  padding: 10px;
  width: 100px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.skill:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.emoji {
  font-size: 3rem;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
}

.label {
  font-size: 1rem;
  color: var(--darkShades);
}

@media (max-width: 768px) {
  .skill {
    width: 80px;
  }

  .emoji {
    font-size: 1.5rem;
  }

  .label {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .skill {
    width: 60px;
  }

  .emoji {
    font-size: 1.2rem;
  }

  .label {
    font-size: 0.8rem;
  }
}