@import "../../resources/colours.css";

.footer {
    background-color: var(--darkShades);
    color: var(--lightShades);
    text-align: center;
    padding: 20px 0;
    width: 100%;
    bottom: 0;
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  
  .footer-links {
    margin-top: 10px;
  }
  
  .footer-links a {
    color: var(--lightShades) ;
    text-decoration: none;
    margin: 0 10px;
    transition: color 0.3s ease;
  }
  
  .footer-links a:hover {
    color: var(--lightAccent);
  }
  