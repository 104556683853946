@import "../../resources/colours.css";

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.headerDiv {
  color: var(--darkShades);
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--lightShades);
  padding: 10px 40px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.headerDiv img {
  max-height: 50px;
  max-width: 100%;
  object-fit: contain;
}

nav {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.menu-icon {
  display: none;
  cursor: pointer;
}

.nav-links {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.nav-links .react-scroll {
  display: flex;
  padding: 10px;
  align-items: center;
  text-decoration: none;
  color: var(--darkShades);
  margin: 0 30px;
  font-size: 25px;
  transition: color 0.3s ease;
}

.nav-links .react-scroll:hover {
  transform: scale(1.05);
}

.social-links {
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-links a {
  margin-left: 25px;
  padding: 5px;
  text-decoration: none;
  align-content: center;
  transition: color 0.3s ease;
}

.social-links a:hover {
  transform: scale(1.05);
}

@media (max-width: 1100px) {
  .headerDiv {
    flex-direction: column;
    padding: 10px 20px;
    
  }

  .headerDiv img {
    display: none;
  }

  .nav-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .nav-links.open {
    display: flex;
  }

  .nav-links .react-scroll {
    margin: 10px 0;
    width: 100%;
    padding-left: 20px;
    justify-content: flex-start;
  }

  .social-links {
    display: none;
  }

  .menu-icon {
    display: block;
  }
}

@media (max-width: 480px) {
  .nav-links .react-scroll {
    font-size: 20px;
    margin: 5px 0;
  }

  .headerDiv img {
    display: none;
  }

  .social-links a {
    display: none;
  }
}